.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 77px;
  background: theme('colors.white/f9fbfc');
  @apply px-32 mb-32;

  &__menu {
    display: flex;
    justify-content: flex-end;
    position: relative;
    @apply w-475;

    &_avatar {
      width: 36px;
      height: 36px;
      margin-right: 8px;
      border-radius: 100%;
    }

    &_name {
      color: theme('colors.dark/42505c');
      font-weight: 700;
      font-size: 14px;
      line-height: normal;
    }

    &_role {
      color: theme('colors.steel');
      font-size: 10px;
    }
  }
}
