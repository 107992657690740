.sidebar {
  width: 15%;
  background: theme('colors.pale-grey');
  overflow: auto;
  @apply py-32;

  &__menu {
    @apply overflow-auto;

    &_title {
      color: theme('colors.gray/bac0c7');
      text-transform: uppercase;
      @apply px-24 mb-10;
    }

    &_item {
      position: relative;
      display: flex;
      align-items: center;
      color: theme('colors.steel');
      font-size: 14px;
      @apply px-24 py-8;

      &.has_submenu {
        justify-content: space-between;
        width: 100%;
      }

      &_active:before,
      &:hover:not(.has_submenu):before {
        position: absolute;
        left: 0;
        display: block;
        width: 6px;
        height: 100%;
        background: theme('colors.azul');
        content: '';
      }
    }

    &_label {
      @apply ml-8;
      text-align: left;
    }

    &_item_active &_label,
    &_item:hover &_label {
      color: theme('colors.azul');
      font-weight: 600;
    }

    &_item_active svg,
    &_item:hover svg {
      color: theme('colors.azul');
    }
  }
}
