@use 'sass:math';

%container-logic {
  width: 100%;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 16px;
  padding-left: 16px;

  @screen md {
    padding-right: 32px;
    padding-left: 32px;
  }
}

.container {
  @extend %container-logic;
  @apply max-w-full;

  @screen md {
    max-width: 98%;
  }

  @screen xl {
    max-width: calc(100% - 19.375%);
  }

  &-fluid {
    @extend %container-logic;
    max-width: 100%;

    > .container {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  @apply -mx-8 md:-mx-12;

  &.reverse {
    flex-direction: row-reverse;
  }

  > * {
    flex: 0 0 auto;
    box-sizing: border-box;
    max-width: 100%;
    @apply px-8 md:px-12;
  }
}

.col-0 {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;

  > .row {
    @apply mx-0;
  }
}

@for $i from 1 through 12 {
  .col-#{$i} {
    flex-basis: math.div(100%, 12) * $i;
    max-width: math.div(100%, 12) * $i;

    > .row {
      @apply mx-0;
    }
  }
}

@screen md {
  .col-md-0 {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;

    > .row {
      @apply mx-0;
    }
  }

  @for $i from 1 through 12 {
    .col-md-#{$i} {
      flex-basis: math.div(100%, 12) * $i;
      max-width: math.div(100%, 12) * $i;

      > .row {
        @apply mx-0;
      }
    }
  }
}
