.master {
  display: flex;
  background: theme('colors.white/f9fbfc');
  height: 100vh;

  &__main {
    width: 85%;
    overflow-y: auto;
  }

  &__ribbon {
    position: fixed;
    top: 50%;
    right: -68px;
    z-index: 9999;
    overflow: hidden;
    height: 57px;
    width: 153px;
    transform: rotate(-90deg);
    background-color: #4dbd74;

    &__span {
      font-size: 12px;
      font-weight: bold;
      color: #fff;
      text-transform: uppercase;
      text-align: center;
      line-height: 20px;
      position: absolute;
      top: 0px;
      bottom: 0px;
      right: 0px;
      padding: 10px;
      user-select: none;
    }
  }

  &__content {
    min-height: 100vh;
    padding-bottom: 40px;
  }
}
