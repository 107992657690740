.drawer {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  background: rgba($color: #000, $alpha: 0.5);
  width: 100%;
  min-height: 100vh;
  z-index: 100;

  &_hidden {
    opacity: 0;
    visibility: hidden;
    z-index: -999;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    color: theme('colors.white');
    position: absolute;
    right: 0;
    @apply px-24 bg-grad-primary;
  }

  &__content {
    top: 64px;
    background: #fff;
    right: 0;
    position: absolute;
    height: 100%;
    min-height: 100vh;
    padding-bottom: 64px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
