.input {
  position: relative;
  display: flex;
  height: 46px;
  background: theme('colors.white');
  border: 1px solid theme('colors.gray/dbe0e4');
  cursor: text;
  @apply rounded-lg transition-all;

  &__field {
    height: 100%;
    color: theme('colors.gray/81848e');
    font-size: 16px;
    background: transparent;
    outline: 0;
    @apply rounded-lg w-full px-14;
  }

  &__disabled {
    background: theme('colors.white/f9fbfc');
  }

  &__prefixed {
    padding-left: 56px;
  }

  &__suffixed {
    padding-right: 40px;
  }

  &__icon {
    color: theme('colors.gray/b8bec8');

    &_suffix,
    &_prefix {
      position: absolute;
      color: theme('colors.gray/81848e');
      @apply transform -translate-y-1/2 top-1/2;
    }

    &_prefix {
      left: 14px;
    }

    &_suffix {
      right: 14px;
    }
  }
}
