.card {
  position: relative;
  // background: theme('colors.white');
  border-radius: 16px;
  box-shadow: 0 4px 32px 16px rgba(0, 0, 0, 0.06);

  &__small {
    border-radius: 8px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.06);
  }
}
