.ribbon {
  position: fixed;
  top: 50%;
  right: -68px;
  z-index: 9999;
  overflow: hidden;
  width: 175px;
  height: 40px;
  text-align: right;
  transform: rotate(-90deg);

  span {
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    width: 175px;
    display: block;
    box-shadow: 0 3px 10px -5px black;
    position: absolute;
    bottom: 0px;
    right: 0px;
    padding: 10px;
    user-select: none;
  }
}
