@use './Button.primary.scss' as primary;

.selectNotActive {
  padding: 16px 16px 16px;
}

.selectActive {
  padding: 23px 16px 16px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.06);
  background-color: #fff;
  position: absolute;
  top: 90px;
  z-index: 9;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  cursor: pointer;
  // border-width: 1px;
  @apply transition-all duration-200;

  .icon-right {
    padding-left: 8px;
  }

  .icon-left {
    padding-right: 8px;
  }

  &:disabled {
    cursor: default;
    background-color: #99a3b0;
    color: #fff;
  }
}

.large {
  height: 56px;
  padding: 16px 24px;
  border-radius: 4px;

  @screen mob {
    height: 46px;
    padding: 12px 20px;
  }
}

.medium {
  height: 46px;
  padding: 8px 16px;
  border-radius: 8px;

  @screen mob {
    height: 36px;
    padding: 8px 12px;
  }
}

.small {
  height: 36px;
  padding: 8px 16px;
  border-radius: 8px;

  @screen mob {
    height: 34px;
    padding: 8px 12px;
  }
}

.primary {
  @include primary.primary;
}

.rounded {
  border-radius: 28px;
}
